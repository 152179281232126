import domReady from '@wordpress/dom-ready';
import $ from 'jquery';

domReady( function () {
	const slick = window.slick;
	const sliders = $( '.wp-block-cloudweb-testimonials' );
	const settings = JSON.parse( sliders.attr( 'data-settings' ) );

	sliders.slick( {
		...settings,
		donts: false,
		responsive: [
			{
				breakpoint: 1023,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	} );
} );


